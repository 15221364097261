import styled from 'styled-components';

import { ViewportBreakpoints } from '../cssConstants';

const HeroH2 = styled.h1.attrs({
  children: props => props.children || props.text,
})`
  font-family: ${({ theme: { fonts } }) => fonts.H1.fontFamily};
  font-weight: 600;
  font-style: normal;
  color: ${({ theme: { colors }, color }) => colors[color]};
  letter-spacing: normal;
  padding: 0;
  
  font-size: 38px;
  line-height: 54px;
  margin: 0 0 30px 0;
  
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    font-size: ${({ theme: { fonts } }) => fonts.H1.fontSize}px;
    line-height: ${({ theme: { fonts } }) => fonts.H1.lineHeight}px;
    margin: 0 0 20px 0;
  }
`;

export default HeroH2;
